export interface PhotoDocType {
  code: string;
  label: string;
  id: string;
  width: number;
  height: number;
  headRatio: number;
  anchorY: number;
  anchorType: string;
  dpi: number;
  imperial: boolean;
  background_color: string;
  sample?: string;
  officialSite?: string;
  flag?: string;
  modified_at?: string;
  validation?: {
    noGlasses?: boolean;
    noJewelry?: boolean;
    allowMouthOpen?: boolean;
  };
}

export const specDigest = (doc: PhotoDocType) => {
  return `${doc.width}_${doc.height}_${doc.headRatio}_${doc.anchorY}_${doc.dpi
    }_${doc.background_color}_${doc.sample?.split('.')[0] ?? 'default'}`
    .split('.')
    .join('-');
};

export const specImageFileName = (doc: PhotoDocType) => {
  return `${specDigest(doc).toLocaleLowerCase()}.webp`;
};

export const documentQueryString = (docType: PhotoDocType): string => {
  const params = new URLSearchParams({
    h: docType.height.toString(),
    w: docType.width.toString(),
    hr: docType.headRatio.toString(),
    ay: docType.anchorY.toString(),
    at: docType.anchorType,
    i: docType.imperial.toString(),
    d: docType.dpi.toString(),
    id: docType.id,
    l: docType.label,
    bg_color: docType.background_color,
  });

  if (docType.validation) {
    const validations: string[] = [];
    if (docType.validation.noGlasses) validations.push('noGlasses');
    if (docType.validation.noJewelry) validations.push('noJewelry');
    if (docType.validation.allowMouthOpen) validations.push('allowMouthOpen');

    if (validations.length > 0) {
      params.append('validations', validations.join(','));
    }
  }

  return params.toString();
};


// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const docTypes: PhotoDocType[] = [
  {
    code: 'SD',
    label: 'Standard 2x2 inch',
    id: 'standard-2x2-passport-photo',
    width: 2,
    height: 2,
    headRatio: 0.65,
    anchorY: 0.8,
    anchorType: 'eye',
    imperial: true,
    dpi: 301,
    background_color: 'ffffff',
    modified_at: '2 Feb 2023',
  },
  {
    code: 'SD',
    label: 'Standard 35 x 45 mm',
    id: 'standard-35x45-passport-photo',
    width: 35,
    height: 45,
    headRatio: 0.76,
    anchorY: 3,
    anchorType: 'hair',
    imperial: false,
    dpi: 301,
    background_color: 'ffffff',
    modified_at: '12 Feb 2023',
  },
  {
    code: 'AU',
    label: 'Australian Visa',
    id: 'australian-visa-photo',
    width: 35,
    height: 45,
    headRatio: 0.75,
    anchorY: 3,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'ffffff',
    modified_at: '18 Feb 2023',
  },
  {
    code: 'BB',
    label: 'Baby Passport 2x2 inch',
    id: 'baby-2x2-passport-photo',
    width: 2,
    height: 2,
    headRatio: 0.65,
    anchorY: 0.8,
    anchorType: 'eye',
    imperial: true,
    dpi: 301,
    background_color: 'ffffff',
    sample: 'Baby.jpg',
    modified_at: '18 Feb 2023',
    validation: {
      allowMouthOpen: true
    }
  },
  {
    code: 'BB',
    label: 'Baby Passport 35 x 45  mm',
    id: 'baby-35x45-passport-photo',
    width: 35,
    height: 45,
    headRatio: 0.76,
    anchorY: 3,
    anchorType: 'hair',
    imperial: false,
    dpi: 301,
    background_color: 'ffffff',
    sample: 'Baby.jpg',
    modified_at: '18 Feb 2023',
    validation: {
      allowMouthOpen: true
    }
  },
  {
    code: 'CM',
    label: 'Cambodian Visa',
    id: 'cambodian-visa-photo',
    width: 2,
    height: 2,
    headRatio: 0.63,
    anchorY: 0.8,
    anchorType: 'eye',
    imperial: true,
    dpi: 300,
    background_color: 'ffffff',
    modified_at: '21 Feb 2023',
  },
  {
    code: 'CA',
    label: 'Canada Visa',
    id: 'canada-visa-photo',
    width: 35,
    height: 45,
    headRatio: 0.75,
    anchorY: 3,
    anchorType: 'hair',
    imperial: false,
    dpi: 300,
    background_color: 'eeeeee',
    modified_at: '28 Feb 2023',
  },
  {
    code: 'CA',
    label: 'Canada Passport',
    id: 'canada-passport-photo',
    width: 50,
    height: 70,
    headRatio: 0.5,
    anchorY: 31,
    anchorType: 'eye',
    imperial: false,
    dpi: 600,
    background_color: 'ffffff',
    officialSite:
      'https://www.canada.ca/en/immigration-refugees-citizenship/services/canadian-passports/photos.html#photo',
    modified_at: '28 Feb 2023',
  },
  {
    code: 'CN',
    label: 'Chinese Passport',
    id: 'chinese-passport-photo',
    width: 33,
    height: 48,
    headRatio: 0.65,
    anchorY: 4,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    flag: 'cn',
    background_color: 'ffffff'
  },
  {
    code: 'CN',
    label: 'Chinese Visa',
    id: 'chinese-visa-photo',
    width: 33,
    height: 48,
    headRatio: 0.65,
    anchorY: 4,
    anchorType: 'hair',
    imperial: false,
    flag: 'cn',
    dpi: 600,
    background_color: 'ffffff',
    officialSite: 'https://www.visaforchina.cn/HKG2_EN/generalinformation/visaknowledge/282828.shtml',
    modified_at: '9 Apr 2024',
    validation: {
      noJewelry: true
    }
  },
  {
    code: 'NE',
    label: 'Dutch Passport',
    id: 'dutch-passport-photo',
    width: 35,
    height: 45,
    headRatio: 0.65,
    anchorY: 6.5,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'eeeeee',
  },
  {
    code: 'FR',
    label: 'French Passport',
    id: 'french-passport-photo',
    width: 35,
    height: 45,
    headRatio: 0.76,
    anchorY: 3,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'eeeeee',
  },
  {
    code: 'DE',
    label: 'German Passport',
    id: 'german-passbild-biometrische',
    width: 35,
    height: 45,
    headRatio: 0.75,
    anchorY: 3,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'ffffff',
  },
  {
    code: 'GR',
    label: 'Greek Passport',
    id: 'greek-passport-photo',
    width: 40,
    height: 60,
    headRatio: 0.65,
    anchorY: 25,
    anchorType: 'eye',
    imperial: false,
    dpi: 300,
    background_color: 'eeeeee',
  },
  {
    code: 'CN',
    label: 'Hong Kong Passport',
    id: 'hongkong-passport-photo',
    width: 40,
    height: 50,
    headRatio: 0.7,
    anchorY: 5,
    anchorType: 'hair',
    imperial: false,
    dpi: 815,
    background_color: 'ffffff',
  },
  {
    code: 'IN',
    label: 'Indian Passport',
    id: 'indian-passport-photo',
    width: 2,
    height: 2,
    headRatio: 0.65,
    anchorY: 0.8,
    anchorType: 'eye',
    imperial: true,
    dpi: 300,
    background_color: 'eeeeee',
  },
  {
    code: 'IN',
    label: 'Indian Visa',
    id: 'indian-visa-photo',
    width: 2,
    height: 2,
    headRatio: 0.65,
    anchorY: 0.8,
    anchorType: 'eye',
    imperial: true,
    dpi: 300,
    background_color: 'eeeeee',
  },
  {
    code: 'ID',
    label: 'Indonesian visa',
    id: 'indonesia-visa-photo',
    width: 40,
    height: 60,
    headRatio: 0.65,
    anchorY: 6,
    anchorType: 'hair',
    imperial: false,
    dpi: 300,
    background_color: 'ffffff',
  },
  {
    code: 'JP',
    label: 'Japanese Passport',
    id: 'japanese-passport-photo',
    width: 35,
    height: 45,
    headRatio: 0.75,
    anchorY: 3,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'fcfcfc',
  },
  {
    code: 'JP',
    label: 'Japanese Visa',
    id: 'japanese-visa-photo',
    width: 35,
    height: 45,
    headRatio: 0.75,
    anchorY: 3,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'fcfcfc',
    modified_at: '22 Feb 2023',
  },
  {
    code: 'JP',
    label: 'Japan Residence Card',
    id: 'japan-residence-photo',
    width: 30,
    height: 40,
    headRatio: 0.75,
    anchorY: 3,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'fcfcfc',
    modified_at: '17 Oct 2023',
  },
  {
    code: 'ML',
    label: 'Malaysian Passport',
    id: 'malaysian-passport-photo',
    width: 35,
    height: 50,
    headRatio: 0.65,
    anchorY: 10,
    anchorType: 'hair',
    imperial: false,
    dpi: 300,
    background_color: 'ffffff',
    modified_at: '11 Feb 2023',
  },
  {
    code: 'ML',
    label: 'Malaysian Visa',
    id: 'malaysian-visa-photo',
    width: 35,
    height: 45,
    headRatio: 0.69,
    anchorY: 5,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'ffffff',
  },
  {
    code: 'MX',
    label: 'Mexican Passport',
    id: 'mexican-passport-photo',
    width: 35,
    height: 45,
    headRatio: 0.75,
    anchorY: 3,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'ffffff',
    modified_at: '6 Aug 2023',
  },
  {
    code: 'RU',
    label: 'Russian Visa',
    id: 'russian-visa-photo',
    width: 35,
    height: 45,
    headRatio: 0.75,
    anchorY: 5,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'ffffff',
  },
  {
    code: 'SA',
    label: 'Saudi Arabia e-visa',
    id: 'saudi-arabia-e-visa-photo',
    width: 2,
    height: 2,
    headRatio: 0.65,
    anchorY: 0.8,
    anchorType: 'eye',
    imperial: true,
    dpi: 300,
    background_color: 'eeeeee',
    modified_at: '10 Feb 2023',
  },
  {
    code: 'EU',
    label: 'Schengen Visa',
    id: 'schengen-visa-application-photo',
    width: 35,
    height: 45,
    headRatio: 0.75,
    anchorY: 3,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'ffffff',
    officialSite: 'https://www.schengenvisainfo.com/photo-requirements/',
    modified_at: '10 Feb 2023',
  },
  {
    code: 'EG',
    label: 'Egyptian Visa',
    id: 'egypt-visa-application-photo',
    width: 35,
    height: 45,
    headRatio: 0.75,
    anchorY: 3,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'ffffff',
    modified_at: '10 Jun 2023',
  }, {
    code: 'SK',
    label: 'South Korean Passport',
    id: 'south-korean-passport-photo',
    width: 35,
    height: 45,
    headRatio: 0.75,
    anchorY: 3,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'fcfcfc',
    modified_at: '21 Feb 2023',
  },
  {
    code: 'ES',
    label: 'Spain residence card (DNI/TIE/NIE)',
    id: 'spain-residence-card-photo',
    width: 26,
    height: 32,
    headRatio: 0.7,
    anchorY: 2,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'ffffff',
    modified_at: '22 Feb 2023',
  },
  {
    code: 'ES',
    label: 'Spanish Passport',
    id: 'spanish-passport-photo',
    width: 30,
    height: 40,
    headRatio: 0.8,
    anchorY: 4,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'ffffff',
    modified_at: '19 Feb 2023',
  },
  {
    code: 'TA',
    label: 'Taiwan Passport',
    id: 'taiwan-passport-photo',
    width: 35,
    height: 45,
    headRatio: 0.75,
    anchorY: 3,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'ffffff',
    modified_at: '15 Feb 2023',
  },
  {
    code: 'TH',
    label: 'Thailand E-Visa',
    id: 'thailand-visa-photo',
    width: 40,
    height: 60,
    headRatio: 0.66,
    anchorY: 6,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'ffffff',
    modified_at: '18 Feb 2023',
  },
  {
    code: 'TR',
    label: 'Turkish Passport',
    id: 'turk-pasaport-fotografi',
    width: 50,
    height: 60,
    headRatio: 0.65,
    anchorY: 10,
    anchorType: 'hair',
    imperial: false,
    dpi: 300,
    background_color: 'eeeeee',
    modified_at: '18 Feb 2023',
  },
  {
    code: 'UK',
    label: 'UK Passport',
    id: 'uk-passport-photo',
    width: 35,
    height: 45,
    headRatio: 0.65,
    anchorY: 6.5,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'eeeeee',
    modified_at: '02 Mar 2023',
  },
  {
    code: 'UAE',
    label: 'UAE Passport',
    id: 'uae-passport-photo',
    width: 40,
    height: 60,
    headRatio: 0.55,
    anchorY: 5,
    anchorType: 'hair',
    imperial: false,
    dpi: 600,
    background_color: 'ffffff',
    modified_at: '27 Feb 2023',
  },
  {
    code: 'US',
    label: 'US Baby Passport',
    id: 'us-baby-passport-photo',
    width: 2,
    height: 2,
    headRatio: 0.65,
    anchorY: 0.8,
    anchorType: 'eye',
    imperial: true,
    dpi: 300,
    background_color: 'ffffff',
    sample: 'Baby.jpg',
    officialSite:
      'https://travel.state.gov/content/travel/en/passports/how-apply/photos.html',
    flag: 'us',
    modified_at: '01 Mar 2023',
  },
  {
    code: 'US',
    label: 'US Green Card',
    id: 'us-green-card-photo',
    width: 2,
    height: 2,
    headRatio: 0.65,
    anchorY: 0.8,
    anchorType: 'eye',
    imperial: true,
    dpi: 300,
    background_color: 'ffffff',
    officialSite:
      'https://travel.state.gov/content/travel/en/passports/how-apply/photos.html',
    flag: 'us',
    modified_at: '01 Mar 2023',
    validation: {
      noGlasses: true
    }
  },
  {
    code: 'US',
    label: 'US Passport',
    id: 'us-passport-photo',
    width: 2,
    height: 2,
    headRatio: 0.65,
    anchorY: 0.8,
    anchorType: 'eye',
    imperial: true,
    dpi: 300,
    background_color: 'ffffff',
    officialSite:
      'https://travel.state.gov/content/travel/en/passports/how-apply/photos.html',
    flag: 'us',
    modified_at: '13 Mar 2023',
    validation: {
      noGlasses: true
    }
  },
  {
    code: 'US',
    label: 'US USCIS',
    id: 'us-uscis-photo',
    width: 2,
    height: 2,
    headRatio: 0.65,
    anchorY: 0.8,
    anchorType: 'eye',
    imperial: true,
    dpi: 301,
    background_color: 'ffffff',
    officialSite:
      'https://travel.state.gov/content/travel/en/passports/how-apply/photos.html',
    flag: 'us',
    modified_at: '01 Mar 2023',
    validation: {
      noGlasses: true
    }
  },
  {
    code: 'US',
    label: 'US Visa',
    id: 'us-visa-photo',
    width: 2,
    height: 2,
    headRatio: 0.65,
    anchorY: 0.8,
    anchorType: 'eye',
    imperial: true,
    dpi: 301,
    background_color: 'ffffff',
    officialSite:
      'https://travel.state.gov/content/travel/en/passports/how-apply/photos.html',
    flag: 'us',
    modified_at: '21 Feb 2023',
    validation: {
      noGlasses: true
    }
  },
  {
    code: 'VN',
    label: 'Vietnamese Passport',
    id: 'vietnamese-passport-photo',
    width: 40,
    height: 60,
    headRatio: 0.65,
    anchorY: 28,
    anchorType: 'eye',
    imperial: false,
    dpi: 300,
    background_color: 'ffffff',
    modified_at: '22 Feb 2023',
  },
  {
    code: 'VN',
    label: 'Vietnamese E-Visa',
    id: 'vietnamese-visa-photo',
    width: 40,
    height: 60,
    headRatio: 0.66,
    anchorY: 6,
    anchorType: 'hair',
    imperial: false,
    dpi: 300,
    background_color: 'ffffff',
    modified_at: '24 Feb 2023',
  },
].map(obj => ({
  ...obj,
  spec_img: specImageFileName(obj)
}));

export const breadcrumbTrail = (docType: PhotoDocType) => {
  const data = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Documents',
        item: `https://www.snap2pass.com/`,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: docType.label,
        item: `https://www.snap2pass.com/doc/${docType.id}`,
      },
    ],
  };
  return data;
};

export const article = (docType: PhotoDocType) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: `Get your ${docType.label} photo!`,
    image: `https://snap2pass.com/spec/${specImageFileName(docType)}`,
    author: {
      '@type': 'Organization',
      url: 'https://snap2pass.com',
      name: 'Snap2Pass.com',
      contactPoint: {
        '@type': 'ContactPoint',
        email: 'info@snap2pass.com',
        contactType: 'Customer service',
        availableLanguage: ['English'],
      },
    },
  };
};

export const readableSize = (doc: PhotoDocType) => {
  const getMetric = (isImperial: boolean) => {
    return isImperial ? 'inches' : 'mm';
  };
  return `${doc.width} x ${doc.height} ${getMetric(doc.imperial)}`;
};

export const readablePrice = (cents: number) => {
  return `\$${(cents / 100).toFixed(2)}`;
};


